import React, { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { getBookingById } from "services/SauceService";

const SubmitContentRoute: React.FC = () => {
  const match = useMatch("/content-submit/:bookId");
  const navigate = useNavigate();

  useEffect(() => {
    const getBooking = async () => {
      if (match?.params.bookId) {
        const booking = await getBookingById(match?.params.bookId);
        navigate("/content", { state: booking });
      }
    };
    getBooking();
  }, [match, navigate]);

  return null;
};

export default SubmitContentRoute;
