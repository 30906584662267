import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Popover,
  Row,
} from "antd";
import { Booking } from "interfaces/booking";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { submitContent } from "services/SauceService";
import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
import "./BookCollab.scss";
import { isMobile } from "react-device-detect";
import { autenticado } from "helpers/auth-functions";

interface BookSubmitContentProps {
  // Add any props you need for your component here
}

export function isValidSocialMediaUrl(url: string): boolean {
  // Regular expression to check if it’s a valid URL and contains “instagram” or “tiktok”
  const regex = /^(https?:\/\/)?(www\.)?(instagram\.com|tiktok\.com)\/.+$/i;
  return regex.test(url);
}

const BookSubmitContent: React.FC<BookSubmitContentProps> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const { state } = location;

  const booking = state as Booking;
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const values = Form.useWatch([], form);

  const containDeliverable = (deliverable: string) => {
    return booking.deliverables
      .map((item) => item.toLowerCase())
      .find((item) => item.includes(deliverable));
  };

  // avoid getting @@profile and return only @profile
  const keepOnlyOneAt = (profile: string) => {
    const regex = /@+/;
    const match = profile.match(regex);
    if (match) {
      return profile.replace(match[0], "@");
    }
    return profile;
  };

  const onClickCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    message.info("Copied to clipboard");
  };

  const onFinishForm = async () => {
    setLoading(true);
    const { instagramPostUrl, instagramStoryUrl, tiktokUrl } = values;

    const params = {
      bookingId: booking.bookingId,
      instagramPostUrl,
      instagramStoryUrl,
      tiktokUrl,
    };
    try {
      await submitContent(params);
      navigate("/submitted-content");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Form form={form} onFinish={onFinishForm}>
      <div style={{ padding: 20 }} className="collab-detail">
        <Row>
          <LeftOutlined
            style={{ cursor: "pointer", marginRight: 12 }}
            onClick={() => navigate(-1)}
          />
          <h2 className="title">Deliverables</h2>
        </Row>
        <div style={{ overflow: "auto", marginBottom: isMobile ? 120 : 100 }}>
          <Row>
            <div className="briefing" style={{ margin: "20px 0" }}>
              {containDeliverable("instagram reel") && (
                <Row gutter={[12, 12]}>
                  <Col xs={24}>
                    <Row justify="space-between">
                      <h4 style={{ margin: 0 }}>Instagram Reel</h4>
                      {booking.compensationCash && (
                        <Popover
                          style={{ width: 200 }}
                          title={null}
                          content={
                            <div
                              style={{
                                fontFamily: "Arial, sans-serif",
                                lineHeight: "1.6",
                              }}
                            >
                              <h2>To mark content as sponsored on Instagram</h2>
                              <p>
                                You can use the{" "}
                                <strong>Branded Content tool</strong> to add a
                                Paid Partnership Label to your post:
                              </p>
                              <ol>
                                <li>Create your content as usual</li>
                                <li>Tap Next</li>
                                <li>Tap Advanced settings</li>
                                <li>
                                  Toggle on{" "}
                                  <strong>Add paid partnership label</strong>
                                </li>
                                <li>
                                  Tap <strong>Add brand partners</strong> to
                                  search for and add up to two brands
                                </li>
                                <li>
                                  If your agreement allows it, toggle on{" "}
                                  <strong>
                                    Allow brand partner to promote
                                  </strong>
                                </li>
                                <li>Tap Done to post your content</li>
                              </ol>
                              <p>
                                The <strong>"Paid Partnership With"</strong>{" "}
                                label will appear above your post to indicate
                                the partnership.
                              </p>
                              <p>
                                You can also access branded content tools by
                                tapping <strong>Creator</strong> in your
                                settings, then tapping{" "}
                                <strong>Branded content</strong>.
                              </p>
                            </div>
                          }
                        >
                          <InfoCircleOutlined style={{ marginLeft: 6 }} />
                        </Popover>
                      )}
                    </Row>
                  </Col>
                  {booking?.collabDetails?.promotionOfferSummary && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramReelCheckOffer"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Add offer{" "}
                          {booking?.collabDetails?.promotionOfferSummary}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  {booking?.collabDetails.instagramAccount && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramReelCheckTagAccount"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Tag{" "}
                          {keepOnlyOneAt(
                            `@${booking?.collabDetails.instagramAccount}`
                          )}{" "}
                          as a collaborator
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24}>
                    <Form.Item
                      name="instagramReelCheckTagMustard"
                      rules={[
                        { required: true },
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Please select this checkbox"),
                        },
                      ]}
                      noStyle
                      valuePropName="checked"
                    >
                      <Checkbox>Tag @mustard.love as a collaborator</Checkbox>
                    </Form.Item>
                  </Col>
                  {booking.compensationCash && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramReelCheckPaidPartnership"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>Add Paid Partnership Label</Checkbox>
                      </Form.Item>
                    </Col>
                  )}

                  {booking?.collabDetails?.promotionActionDm && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramReelCheckPromotionActionDm"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Ask your followers to DM{" "}
                          {booking?.collabDetails?.promotionActionDm} to receive{" "}
                          {booking?.collabDetails?.promotionOfferSummary}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24}>
                    <Form.Item
                      name="instagramPostUrl"
                      rules={[
                        { required: true },
                        {
                          validator: (_, value) =>
                            isValidSocialMediaUrl(value)
                              ? Promise.resolve()
                              : Promise.reject("Please enter a valid URL"),
                        },
                      ]}
                    >
                      <Input placeholder="Enter the URL of your Reel" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {containDeliverable("instagram story") && (
                <Row gutter={[12, 12]}>
                  <Col xs={24}>
                    <h4 style={{ margin: 0 }}>Instagram Story</h4>
                  </Col>
                  {booking?.collabDetails?.promotionOfferSummary && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramStoryCheckOffer"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Add story with offer{" "}
                          {booking?.collabDetails?.promotionOfferSummary}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  {booking?.collabDetails.instagramAccount && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramStoryCheckTagAccount"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Tag{" "}
                          {keepOnlyOneAt(
                            `@${booking?.collabDetails.instagramAccount}`
                          )}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24}>
                    <Form.Item
                      name="instagramStoryCheckTagMustard"
                      rules={[
                        { required: true },
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Please select this checkbox"),
                        },
                      ]}
                      noStyle
                      valuePropName="checked"
                    >
                      <Checkbox>Tag @mustard.love</Checkbox>
                    </Form.Item>
                  </Col>
                  {booking?.collabDetails?.promotionSwipeLink && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramStoryCheckSwipeUpLink"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Add this swipe-up link to your story
                        </Checkbox>
                      </Form.Item>
                      <span
                        className="copiable-info"
                        style={{ marginLeft: 26 }}
                        onClick={() =>
                          onClickCopy(
                            booking?.collabDetails?.promotionSwipeLink
                          )
                        }
                      >
                        {booking?.collabDetails?.promotionSwipeLink}
                        <CopyIcon />
                      </span>
                    </Col>
                  )}
                  {booking?.collabDetails?.promotionCaptionLink && (
                    <Col xs={24}>
                      <Form.Item
                        name="instagramStoryCheckSwipeUpCaption"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>Use this caption for the swipe-up</Checkbox>
                      </Form.Item>
                      <span
                        className="copiable-info"
                        style={{ marginLeft: 26 }}
                        onClick={() =>
                          onClickCopy(
                            booking?.collabDetails?.promotionCaptionLink
                          )
                        }
                      >
                        {booking?.collabDetails?.promotionCaptionLink}
                        <CopyIcon />
                      </span>
                    </Col>
                  )}
                  <Col xs={24}>
                    <Form.Item
                      name="instagramStoryUrl"
                      rules={[
                        { required: true },
                        {
                          validator: (_, value) =>
                            isValidSocialMediaUrl(value)
                              ? Promise.resolve()
                              : Promise.reject("Please enter a valid URL"),
                        },
                      ]}
                    >
                      <Input placeholder="Enter the URL of your Story" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {containDeliverable("tiktok video") && (
                <Row gutter={[12, 12]}>
                  <Col xs={24}>
                    <h4 style={{ margin: 0 }}>TikTok Video</h4>
                  </Col>
                  {booking?.collabDetails?.promotionOfferSummary && (
                    <Col xs={24}>
                      <Form.Item
                        name="tiktokVideoCheckOffer"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Add video with offer{" "}
                          {booking?.collabDetails?.promotionOfferSummary}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  {booking?.collabDetails?.tiktokAccount && (
                    <Col xs={24}>
                      <Form.Item
                        name="tiktokVideoCheckTagAccount"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Please select this checkbox"),
                          },
                        ]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Tag{" "}
                          {keepOnlyOneAt(
                            `@${booking?.collabDetails.tiktokAccount}`
                          )}{" "}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24}>
                    <Form.Item
                      name="tiktokVideoCheckTagMustard"
                      rules={[
                        { required: true },
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Please select this checkbox"),
                        },
                      ]}
                      noStyle
                      valuePropName="checked"
                    >
                      <Checkbox>Tag @mustard.love</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="tiktokUrl"
                      rules={[
                        { required: true },
                        {
                          validator: (_, value) =>
                            isValidSocialMediaUrl(value)
                              ? Promise.resolve()
                              : Promise.reject("Please enter a valid URL"),
                        },
                      ]}
                    >
                      <Input placeholder="Enter the URL of your TikTok post" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </Row>
          <Row
            style={{
              position: isMobile ? "fixed" : "inherit",
              width: isMobile ? "calc(100% - 24px)" : "100%",
              left: isMobile ? 12 : "50%",
              bottom: isMobile && autenticado() ? 100 : 10,
            }}
          >
            <Button
              size="large"
              type="primary"
              shape="round"
              onClick={() => form.submit()}
              style={{ flex: 1 }}
              disabled={!submittable}
              loading={loading}
            >
              {booking.compensationCash
                ? `Claim $${booking.compensationCash}`
                : "Submit content"}
            </Button>
          </Row>
        </div>
      </div>
    </Form>
  );
};

export default BookSubmitContent;
