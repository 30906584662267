import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserLoggedInfo, verifyStripeStatus } from "services/SauceService";

export const getLoggedUserInfo = createAsyncThunk(
  "user/getLoggedUserInfo",
  async () => {
    const userInfo: any = await getUserLoggedInfo();
    return userInfo;
  }
);

export const getStripeAccount = createAsyncThunk(
  "user/getStripeAccount",
  async (data: any) => {
    const stripeAccount: any = await verifyStripeStatus(data);
    return stripeAccount;
  }
);

export interface FindLocationCollabs {
  city: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}
export const userSlice: any = createSlice({
  name: "user",
  initialState: {
    loading: true,
    loggedUser: {},
    stripeConnected: false,
    stripeInfo: null,
    findCollabslocation: {},
  },
  reducers: {
    setLocation: (state, action) => {
      state.findCollabslocation = action.payload;
      localStorage.setItem(
        "findCollabslocation",
        JSON.stringify(action.payload)
      );
    },
    bootLocation: (state) => {
      const location = localStorage.getItem("findCollabslocation");
      if (location) {
        state.findCollabslocation = JSON.parse(location);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedUserInfo.pending, (state) => {
      state.loggedUser = {};
      state.loading = true;
    });

    builder.addCase(getLoggedUserInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.loggedUser = action.payload;
        localStorage.setItem("foodieId", action.payload._id);
      }
      state.loading = false;
    });

    builder.addCase(getLoggedUserInfo.rejected, (state) => {
      state.loggedUser = {};
      state.loading = false;
    });

    builder.addCase(getStripeAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.stripeConnected = action.payload.complete;
        state.stripeInfo = action.payload;
      }
    });
  },
});

export const { setLocation, bootLocation } = userSlice.actions;

export default userSlice.reducer;
