import {
  CloseCircleOutlined,
  LeftOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Col, Modal, Row, Typography } from "antd";
import { Location, ProfileStatus, UserProfile } from "interfaces/user-profile";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoggedUserInfo } from "redux-context/user";
import { updateUserInfoLocation } from "services/SauceService";
import AddLocation from "./AddLocation";

const { confirm } = Modal;
interface Props {}

const BestCoverage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );
  const [addingLocation, setAddingLocation] = useState<boolean>(false);

  const updateUserInfoCategory = useCallback(
    async (locations: Location[]) => {
      await updateUserInfoLocation({ locations });
      dispatch(getLoggedUserInfo());
      if (loggedUser.profileStatus !== ProfileStatus.LocationPending) {
        navigate("/");
        return;
      }
    },
    [navigate, dispatch, loggedUser]
  );

  const onSavedLocation = (location: Location) => {
    const newLocations = loggedUser?.locations
      ? [...loggedUser?.locations, location]
      : [location];
    updateUserInfoCategory(newLocations);
    setAddingLocation(false);
  };

  const onClickRemove = (location: Location) => {
    confirm({
      title: "Delete location",
      content: "Are you sure you want to delete?",
      icon: null,
      onOk() {
        const newLocations = loggedUser?.locations?.filter(
          (item) => item.name !== location.name
        );

        updateUserInfoCategory(newLocations);
      },
      onCancel() {
        console.log("Cancel");
      },
      cancelText: "No",
      okText: "Yes",
    });
  };

  const onAddLocationClick = () => {
    setAddingLocation(true);
  };

  return (
    <div className="coverage profile">
      <Row style={{ alignItems: "center", marginTop: 20 }}>
        {loggedUser.profileStatus !== ProfileStatus.LocationPending && (
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        )}
        <Typography.Title level={3} style={{ margin: "auto 20px" }}>
          Best coverage in
        </Typography.Title>
      </Row>
      <Row>
        <span className="general-sub-title">
          Provide zip codes were you post the most
        </span>
      </Row>
      {addingLocation ? (
        <Row style={{ marginTop: 20 }}>
          <Col xs={24}>
            <AddLocation
              onCancel={() => setAddingLocation(false)}
              onSaved={onSavedLocation}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginTop: 20 }}>
            {loggedUser?.locations?.map((item) => (
              <Col xs={24} key={`LOCATION_${item?.name}`}>
                <div className="location">
                  <span className="item">{item.name}</span>
                  <CloseCircleOutlined
                    style={{ fontSize: 20, cursor: "pointer" }}
                    onClick={() => onClickRemove(item)}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <div className="add-location" onClick={onAddLocationClick}>
              <PlusCircleOutlined />
              <span>Add another location</span>
            </div>
          </Row>
        </>
      )}
    </div>
  );
};

export default BestCoverage;
