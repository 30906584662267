import { LeftOutlined } from "@ant-design/icons";
import { Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { ReactComponent as TikTokIcon } from "assets/images/tiktok-icon.svg";
import { ProfileStatus, UserProfile } from "interfaces/user-profile";
import { ReactComponent as LogoutIcon } from "assets/images/logout-icon.svg";
import { logout } from "helpers/auth-functions";
import FacebookButton from "components/facebook-button/FacebookButton";
import { useSelector } from "react-redux";

interface SocialMediaProps {
  // Add any props you need here
}

const SocialMedia: React.FC<SocialMediaProps> = () => {
  const navigate = useNavigate();
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );

  const instagram = loggedUser?.socialMedias?.find(
    (item) => item.socialMediaName?.toLocaleLowerCase() === "instagram"
  );

  const tiktok = loggedUser?.socialMedias?.find(
    (item) => item.socialMediaName?.toLocaleLowerCase() === "tiktok"
  );

  const verification = loggedUser?.profileStatus !== ProfileStatus.Active;

  const onLogoutClick = () => {
    logout();
  };

  return (
    <div className="social-media profile">
      <Row
        style={{ alignItems: "center", marginTop: 20 }}
        justify="space-between"
      >
        <div style={{ display: "flex" }}>
          {!verification && (
            <LeftOutlined
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          )}
          <Typography.Title level={3} style={{ margin: "auto 20px" }}>
            Social media
          </Typography.Title>
        </div>
        {verification && (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoutIcon
              style={{ width: 20, height: 20 }}
              onClick={() => onLogoutClick()}
            />
          </div>
        )}
      </Row>
      <div className="content">
        <div className="media">
          {instagram ? (
            <>
              <InstagramIcon />
              <b>{instagram.socialMediaUserName}</b>
            </>
          ) : (
            <FacebookButton asLabel />
          )}
        </div>
        {tiktok && (
          <div className="media">
            <TikTokIcon />
            <b> {tiktok.socialMediaUserName}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default SocialMedia;
