import { LeftOutlined } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import LimitedAvailability from "assets/images/limited-availability.png";
import "./BookCollab.scss";
// import dayjs from "dayjs";
import { newBookingRequest } from "services/SauceService";
import { useState } from "react";

const deliverablesObjectToArray = (deliverables: any) => {
  let deliverablesArray: any = [];
  if (deliverables?.instagramReel) {
    deliverablesArray.push("Instagram Reel");
  }
  if (deliverables?.instagramStory) {
    deliverablesArray.push("Instagram Story");
  }
  if (deliverables?.tiktokVideo) {
    deliverablesArray.push("TikTok Video");
  }
  return deliverablesArray;
};
const BookCollabConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state);
  const [loading, setLoading] = useState<boolean>(false);
  // const selectedDate = state?.selectedDate?.format
  //   ? state.selectedDate.format("dddd, MMM DD, ")
  //   : "";

  const onClickRequest = async () => {
    const { spotId, previousBookingId } = state;
    const params = {
      collabId: state?.collab?.collabId,
      preferredDates: state?.selectedSlots.map((slot: any) => ({
        date: slot?.date?.toISOString(),
        hour: slot.time,
      })),
      compensation: {
        deliverables: deliverablesObjectToArray(state?.collab?.deliverables),
        spotId,
      },
      previousBookingId,
    };
    try {
      setLoading(true);
      const response = await newBookingRequest(params);
      console.log(response);
      if (response) navigate("/book/collab-success", { state });
    } catch {
      navigate("/book/collab-failed", { state });
    } finally {
      setLoading(false);
    }

    // const path = randomBoolean ? "/book/collab-success" : "/book/collab-failed";
    // navigate(path, { state });
  };

  return (
    <div className="book-confirmation green">
      <div>
        <Row
          justify="space-between"
          style={{ alignItems: "center", marginTop: 20 }}
        >
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Row>
        <Typography.Title level={3} style={{ margin: "36px 0 16px 0" }}>
          Request collab?
        </Typography.Title>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {state?.collab?.venue?.name}
        </Typography.Title>
        <Typography.Text style={{ margin: 0 }}>
          {state?.selectedSlots.map((slot: any) => (
            <span style={{ display: "block" }}>
              {`${slot.date.format("dddd, MMM DD, ")} ${slot.time}`}
            </span>
          ))}
        </Typography.Text>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={LimitedAvailability} alt="collab" />
        </div>
        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Limited availability!
          </Typography.Title>
          <Typography.Text>
            Not all applications will be approved. Apply early to boost your
            chances.
          </Typography.Text>
        </div>
      </div>
      <Button type="primary" onClick={onClickRequest} loading={loading}>
        Send request
      </Button>
    </div>
  );
};

export default BookCollabConfirmation;
