import { FacebookFilled } from "@ant-design/icons";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { Button } from "antd";
import { autenticado, TOKEN } from "helpers/auth-functions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInMeta } from "services/SauceService";

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

const permissions = [
  "public_profile",
  "email",
  "pages_show_list",
  "instagram_basic",
];

const FacebookButton: React.FC<{ asLabel?: boolean }> = (props) => {
  const { asLabel } = props;
  const navigate = useNavigate();

  useEffect(() => {
    window.fbAsyncInit = () => {
      console.log("Facebook SDK loaded");
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v13.0",
      });

      window.FB.AppEvents.logPageView();
    };

    // Carrega o SDK do Facebook
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const checkLoginState = () => {
    window.FB.getLoginStatus((response: any) => {
      statusChangeCallback(response);
    });
  };

  const fetchUserProfile = async () => {
    try {
      const response: any = await new Promise((resolve, reject) => {
        window.FB.api("/me", (response: any) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        });
      });

      // console.log("Successful login for: ", response);
      return response;
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  // const fetchUserBusinesses = () => {
  //   window.FB.api("/me/businesses", (response: any) => {
  //     // message.success(`Business name: ${response.data[0].name}`);
  //     console.log("Business", response);
  //   });
  // };
  const fetchUserPermissions = async () => {
    try {
      const response: any = await new Promise((resolve, reject) => {
        window.FB.api("/me/permissions", (response: any) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        });
      });

      const { acceptedPermissions, declinedPermissions } = response.data.reduce(
        (
          acc: { acceptedPermissions: string[]; declinedPermissions: string[] },
          p: any
        ) => {
          if (p.status === "granted") {
            acc.acceptedPermissions.push(p.permission);
          } else if (p.status === "declined") {
            acc.declinedPermissions.push(p.permission);
          }
          return acc;
        },
        { acceptedPermissions: [], declinedPermissions: [] }
      );

      return { acceptedPermissions, declinedPermissions };
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  };

  const statusChangeCallback = async (response: any) => {
    if (response.status === "connected") {
      // Logged into your app and Facebook.
      console.log("User is logged in with Facebook");

      const profile = await fetchUserProfile();
      const permissions = await fetchUserPermissions();
      // fetchUserBusinesses();
      console.log("Profile", profile);
      console.log("Permissions", permissions);
      console.log("login status", response);

      const params = {
        accessToken: response.authResponse.accessToken,
        permissions: permissions?.acceptedPermissions,
        declinedPermissions: permissions?.declinedPermissions,
        userID: profile.id,
        internalaccesstoken:
          (autenticado() && localStorage.getItem(TOKEN)) || undefined,
      };
      const loginInfo: any = await signInMeta(params);
      console.log("Login info", loginInfo);
      navigate("/signin-link?accessToken=" + loginInfo.accessToken);
    } else {
      // The person is not logged into your app or we are unable to tell.
      console.log("User is not logged in with Facebook");
    }
  };

  const handleLogin = () => {
    window.FB.login(checkLoginState, {
      scope: permissions.join(","),
      enable_profile_selector: true,
    });
  };

  if (asLabel) {
    return (
      <div className="media" style={{ cursor: "pointer" }}>
        <InstagramIcon />
        <span onClick={handleLogin}>
          <b>Please connect your Instagram</b>
        </span>
      </div>
    );
  }

  return (
    <Button
      style={{ borderRadius: 12 }}
      icon={<FacebookFilled />}
      onClick={handleLogin}
    />
  );
};

export default FacebookButton;
