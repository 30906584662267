import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Row, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import EmptyItems from "assets/images/empty-itens.png";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import "./Collabs.scss";
import dayjs from "dayjs";
import { BookingStatus } from "enums/BookingStatus";

interface HistoryItem {
  collabId: string;
  bookingId: string;
  applicationDate: {
    hour: string;
    date: string;
  };
  slotSizeMinutes: number;
  compensationCash: number;
  compensationFood: number;
  guestAllowed: boolean;
  deliverables: string[];
  venue: {
    addressCoordinates: {
      coordinates: number[];
      type: string;
    };
    address: string;
    name: string;
    uuid: string;
    city: string;
    cityState: string;
  };
  attendanceConfirmation: boolean;
  collabDetails: {
    startDate: string;
    endDate: string;
    status: number;
  };
  status: BookingStatus;
  securityCode: string;
  dateSubmittedContent: string;
}
interface HighlightedTextProps {
  text: string;
  highlight: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = ({
  text,
  highlight,
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>; // Retorna o texto completo se não houver termo de destaque
  }

  // Cria uma expressão regular para buscar o termo destacado
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span style={{ backgroundColor: "#E2F2DC" }} key={index}>
            {part}
          </span> // Destaca a parte que corresponde ao termo de pesquisa
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};
const History: React.FC = () => {
  const historyList = useLoaderData() as HistoryItem[];
  const navigate = useNavigate();
  const listEmpty = historyList?.length === 0;

  const [filter, setFilter] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const filteredList = useMemo(() => {
    return historyList.filter((item) => {
      return (
        item.venue.name.toLowerCase().includes(filter.toLowerCase()) ||
        item.venue.address.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }, [filter, historyList]);

  const groupedByMonth = useMemo(() => {
    return historyList.reduce((acc, item) => {
      const month = dayjs(item.applicationDate.date).format("MMMM YYYY");
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {} as { [key: string]: HistoryItem[] });
  }, [historyList]);

  const labelStatus = (status: BookingStatus) => {
    //     6 => Completed (cinza)
    // 5 => Submitted + prop dateSubmittedContent (cinza)
    // 7,8,10 => Cancelled

    switch (status) {
      case 5:
        return "Submitted";
      case 6:
        return "Completed";
      case 7:
      case 8:
      case 10:
        return "Cancelled";
      default:
        return "";
    }
  };

  const colorByStatus = (status: BookingStatus) => {
    switch (status) {
      case 5:
        return "#666";
      case 6:
        return "#666";
      case 7:
      case 8:
      case 10:
        return "#FF354A";
      default:
        return "";
    }
  };

  return (
    <div className="history">
      <div className="header">
        <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
          <LeftOutlined
            onClick={() => navigate("/", { state: { back: true } })}
          />
          {!showFilter && (
            <Typography.Title level={3}>History</Typography.Title>
          )}
        </div>
        {!showFilter ? (
          <SearchOutlined onClick={() => setShowFilter(true)} />
        ) : (
          <Input
            allowClear
            onClear={() => setShowFilter(false)}
            style={{ width: "100%", backgroundColor: "transparent" }}
            placeholder="Search for a collab"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        )}
      </div>
      <div className="content">
        {listEmpty ? (
          <>
            <img src={EmptyItems} alt="" style={{ width: "50%" }} />
            <span className="title">No history yet</span>
            <span className="sub-title">
              Here will be a history of your collabs
            </span>
            <Button
              type="primary"
              style={{ marginTop: 32 }}
              onClick={() => navigate("/find-collabs")}
            >
              Find Collab
            </Button>
          </>
        ) : (
          <div className="content-due">
            {showFilter ? (
              filteredList.length > 0 ? (
                filteredList.map((collab, index) => (
                  <div
                    className="collab"
                    key={`${index}_CONTENT_DUE_${collab.collabId}`}
                  >
                    <div
                      className="collab-content"
                      style={{ display: "flex" }}
                      onClick={() =>
                        navigate(`/book/detail/${collab.bookingId}`)
                      }
                    >
                      <div style={{ flex: 3 }}>
                        <Row style={{ justifyContent: "space-between" }}>
                          <span
                            className="ellipsis"
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              maxWidth: 300,
                            }}
                          >
                            <HighlightedText
                              text={collab.venue.name}
                              highlight={filter}
                            />
                            {/* {collab.venue.name} */}
                          </span>
                        </Row>
                        <div className="row">
                          <LocationIcon style={{ width: 16, height: 16 }} />
                          <span
                            className="ellipsis general-sub-title"
                            style={{ maxWidth: 230 }}
                          >
                            {collab.venue.address}
                          </span>
                        </div>
                        <div className="row">
                          <CalendarIcon style={{ width: 16, height: 16 }} />
                          <span
                            className="ellipsis general-sub-title"
                            style={{ maxWidth: 230 }}
                          >
                            {dayjs(collab.applicationDate.date).format(
                              "MMM DD"
                            )}
                          </span>
                          {" ∘ "}
                          <span style={{ color: colorByStatus(collab.status) }}>
                            {labelStatus(collab.status)}
                            {collab.status ===
                              BookingStatus.PendingContentReview &&
                              ` ${dayjs(collab.dateSubmittedContent).format(
                                "MMMM DD"
                              )}`}
                          </span>
                        </div>
                        <Row style={{ gap: 8, marginTop: 12 }}>
                          {collab.guestAllowed && (
                            <div className="box-perk">
                              <span className="emoji-size">🍴</span>
                              <div className="value">
                                <span>Guests</span>
                                <span>
                                  <b>+1</b>
                                </span>
                              </div>
                            </div>
                          )}
                          {(collab?.compensationFood || 0) > 0 && (
                            <div className="box-perk">
                              <span className="emoji-size">🍔</span>
                              <div className="value">
                                <span>Food</span>
                                <span>
                                  <b>${collab?.compensationFood}</b>
                                </span>
                              </div>
                            </div>
                          )}
                          {(collab?.compensationCash || 0) > 0 && (
                            <div className="box-perk">
                              <span className="emoji-size">💸</span>
                              <div className="value">
                                <span>Comp</span>
                                <span>
                                  <b>${collab.compensationCash}</b>
                                </span>
                              </div>
                            </div>
                          )}
                        </Row>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center" }}>
                  <img src={EmptyItems} alt="" style={{ width: "50%" }} />
                  <span className="title">Restaurant not found </span>
                </div>
              )
            ) : (
              Object.keys(groupedByMonth).map((month, index) => (
                <div key={`${index}_MONTH_${month}`}>
                  <Typography.Title level={4}>{month}</Typography.Title>
                  <div className="content-due">
                    {groupedByMonth[month].map((collab, index) => (
                      <div
                        className="collab"
                        key={`${index}_CONTENT_DUE_${collab.collabId}`}
                      >
                        <div
                          className="collab-content"
                          style={{ display: "flex" }}
                          onClick={() =>
                            navigate(`/book/detail/${collab.bookingId}`)
                          }
                        >
                          <div style={{ flex: 3 }}>
                            <Row style={{ justifyContent: "space-between" }}>
                              <span
                                className="ellipsis"
                                style={{
                                  fontSize: 18,
                                  fontWeight: 600,
                                  maxWidth: 300,
                                }}
                              >
                                {collab.venue.name}
                              </span>
                            </Row>
                            <div className="row">
                              <LocationIcon style={{ width: 16, height: 16 }} />
                              <span
                                className="ellipsis general-sub-title"
                                style={{ maxWidth: 230 }}
                              >
                                {collab.venue.address}
                              </span>
                            </div>
                            <div className="row">
                              <CalendarIcon style={{ width: 16, height: 16 }} />
                              <span
                                className="ellipsis general-sub-title"
                                style={{ maxWidth: 230 }}
                              >
                                {dayjs(collab.applicationDate.date).format(
                                  "MMM DD"
                                )}
                              </span>
                              {" ∘ "}
                              <span
                                style={{
                                  color: colorByStatus(collab.status),
                                }}
                              >
                                {labelStatus(collab.status)}
                                {collab.status ===
                                  BookingStatus.PendingContentReview &&
                                  ` ${dayjs(collab.dateSubmittedContent).format(
                                    "MMMM DD"
                                  )}`}
                              </span>
                            </div>
                            <Row style={{ gap: 8, marginTop: 12 }}>
                              {collab.guestAllowed && (
                                <div className="box-perk">
                                  <span className="emoji-size">🍴</span>
                                  <div className="value">
                                    <span>Guests</span>
                                    <span>
                                      <b>+1</b>
                                    </span>
                                  </div>
                                </div>
                              )}
                              {(collab?.compensationFood || 0) > 0 && (
                                <div className="box-perk">
                                  <span className="emoji-size">🍔</span>
                                  <div className="value">
                                    <span>Food</span>
                                    <span>
                                      <b>${collab?.compensationFood}</b>
                                    </span>
                                  </div>
                                </div>
                              )}
                              {(collab?.compensationCash || 0) > 0 && (
                                <div className="box-perk">
                                  <span className="emoji-size">💸</span>
                                  <div className="value">
                                    <span>Comp</span>
                                    <span>
                                      <b>${collab.compensationCash}</b>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Row>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default History;
