import { LeftOutlined } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import CollabSuccess from "assets/images/collab-success.png";
import "./BookCollab.scss";

const BookCollabSuccess: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="book-confirmation green">
      <div>
        <Row
          justify="space-between"
          style={{ alignItems: "center", marginTop: 20 }}
        >
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Row>
        <Typography.Title level={3} style={{ margin: "36px 0 16px 0" }}>
          Collab request sent
        </Typography.Title>

        <Typography.Text style={{ margin: 0 }}>
          We will confirm the collab with the business and notify you.
        </Typography.Text>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={CollabSuccess} alt="collab" />
        </div>
      </div>
      <div className="buttons">
        <Button type="text" onClick={() => navigate("/find-collabs")}>
          Back to Collabs
        </Button>
      </div>
    </div>
  );
};

export default BookCollabSuccess;
