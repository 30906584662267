import { useDispatch } from "react-redux";
import {
  createRoutesFromChildren,
  Outlet,
  useLocation,
  useNavigationType,
  matchRoutes,
} from "react-router-dom";
import { useEffect } from "react";
import { autenticado } from "helpers/auth-functions";
import { bootLocation, getLoggedUserInfo } from "redux-context/user";
import * as Sentry from "@sentry/react";
import "./App.scss";
// import * as rdd from "react-device-detect";

// (rdd as any).isMobile = true;
// (rdd as any).isDesktop = false;

Sentry.init({
  dsn: "https://445fb2646dcd3204d0de456991740e2f@o4506367764332544.ingest.us.sentry.io/4508163700621312",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  const dispatch = useDispatch<any>();

  // const { loading } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (autenticado()) {
      dispatch(getLoggedUserInfo());
    }
    dispatch(bootLocation());
  }, [dispatch]);

  return <Outlet />;
}

export default App;
